html,
body,
#root {
  height: 100%;
  min-width: 100%;
  background-color: gray;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;

}

.profilePhoto {
  width: 250px;
}

.homepage {
  min-height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  align-items: center;
}

.contact {
  text-align: center;
  background-color: white;
  padding: 10%;
  margin: 5%;
  min-height: 80%;
}

h1 {
  font-size: 45px;
}

.name {
  font-weight: 300;
}

.title {
  margin-bottom: 2em;
}

.about {
  padding: 10%;

}

p {
  font-size: 18px;
  font-weight: 500;
  margin-top: 3em;
  margin-bottom: 4em;
}


.link {
  background-color: rgb(46, 46, 46);
  color: white;
  padding: 1em 1.5em;
  text-decoration: none;
  margin: 1em;
  font-weight: 600;
  border: 2px solid black;
  text-align: center;
}

.link:hover {
  background-color: white;
  color: black;
}

.contactLink {
  margin-top: 1em;
}

.resume {
  display: grid;
  grid-template-columns: 350px 1fr;
  grid-template-rows: 50px max-content;
}


.start {
  text-decoration: none;
  font-weight: bold;
  font-size: 18px;
  margin-left: 3em;
  line-height: 3em;
  text-align: left;
  grid-column: 1;
  grid-row: 1;
}

.start:visited {
  color: black;
}

.sectionList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  height: fit-content;
  grid-column: 1;
  grid-row: 2;
  justify-self: center;
}

.section {
  min-width: 230px;
}

.CVlist {
  min-width: 200px;
  grid-column: 2;
  grid-row: 2;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  justify-content: center;
  align-items: start;
}

.CVitem {
  background-color: white;
  border-radius: 10px;
  padding: 1em;
  margin: 1em;
  text-size-adjust: auto;
  border: 2px solid white;
  width: 350px;
  height: 350px;
}

.CVitem:hover {
  border: 2px solid black;
}

.CVitem .time {
  font-style: italic;
  font-weight: 300;
  margin: 1em 0;
}

.CVitem .title {
  font-size: 20px;
  font-weight: 500;
  margin: 1em 0;
}

.CVitem .description {
  font-weight: 400;
  margin: 1em 0;
}

.download .link {
  background-color: white;
  color: black;
  margin-top: 50px;
  justify-self: center;
  align-self: center;
}



@media screen and (max-width: 650px) {
  .resume {
    grid-template-columns: 1fr;
    grid-template-rows: 50px max-content max-content 50px;
  }

  .CVlist {
    grid-row: 3;
    grid-column: 1;
    justify-self: center;
  }

}